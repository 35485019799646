
import { defineComponent } from 'vue';

import HeaderImageBoxStart from '../../components/Frontend/HeaderImageBoxStart.vue';

export default defineComponent({
  components: {
    HeaderImageBoxStart,
  },
});
