<style lang="scss">
#debug {
  display: none;
}

// Mobile 414x736
// Tablet 1024x768
// Desktop 1280x800 // 1366x768

#backend {
  .header-top {
    //border:1px solid red;
    //background:red;
    position: sticky;
    display: flex;
    justify-content: flex-end;
    top: 0;
    z-index: 500;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 15px;
    display:none;
    @media (max-width: $width-xl) {
      top: 70px;
    }
  }
  .device-outer-all {
    //overflow:hidden;

    .device-mobile-outer {
      padding: 50px 0;
      .device-mobile-inner {
        display: inline-block;

        padding: 25px;
        border-radius: 25px;
        background: rgb(204, 204, 204);
        background: linear-gradient(6deg, rgba(204, 204, 204, 1) 0%, rgba(238, 238, 238, 1) 100%);
        width: 464px;
        height: 736px;
        //border:1px solid red;
        position: relative;

        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        &:after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #fff;
          position: absolute;
          left: 50%;
          margin-left: -10px;
          bottom: 20px;
        }
        &:before {
          content: '';
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #eee;
          position: absolute;
          left: 50%;
          margin-left: -15px;
          bottom: 15px;
        }
        iframe {
          //border:1px solid blue;
          overflow: hidden;
          width: 414px;
          height: 650px;
        }
      }
    }
    .device-tablet-outer {
      padding: 50px 0;
      .device-tablet-inner {
        display: inline-block;
        padding: 25px;
        border-radius: 25px;
        background: rgb(204, 204, 204);
        background: linear-gradient(6deg, rgba(204, 204, 204, 1) 0%, rgba(238, 238, 238, 1) 100%);
        width: 818px;
        height: 1024px;
        //border:1px solid red;
        position: relative;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        @media(max-width:814px) {
          width:90%;
          margin:0 auto;
          height:800px;
        }
        @media(max-width:$width-md) {
          display:none;
        }
        &:after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #fff;
          position: absolute;
          left: 50%;
          margin-left: -10px;
          bottom: 20px;
        }
        &:before {
          content: '';
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #eee;
          position: absolute;
          left: 50%;
          margin-left: -15px;
          bottom: 15px;
        }
        iframe {
          //border:1px solid blue;
          overflow: hidden;
          width: 768px;
          height: 940px;
          @media(max-width:814px) {
          width:100%;
          height:700px;
        }
        }
      }
    }
    .device-desktop-outer {
      padding: 50px 0;
      //border:1px solid red;
      @media(max-width:1280px) {
        display:none;
      }
      .device-desktop-inner {
        display: inline-block;
        //padding:25px;
        border-radius: 25px;
        background: rgb(204, 204, 204);
        background: linear-gradient(6deg, rgba(204, 204, 204, 1) 0%, rgba(238, 238, 238, 1) 100%);
        width: 100%;
        height: 800px;
        //border:1px solid red;
        position: relative;
        box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
        //margin:0 auto;
        padding: 25px;
        &:after {
          content: '';
          display: block;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #fff;
          position: absolute;
          left: 50%;
          margin-left: -10px;
          bottom: 25px;
        }
        &:before {
          content: '';
          display: block;
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 1px solid #eee;
          position: absolute;
          left: 50%;
          margin-left: -15px;
          bottom: 20px;
        }
        iframe {
          //border:1px solid blue;
          overflow: hidden;
          width: 100%;
          position: relative;
          height: 700px;
        }
      }
      .device-desktop-socket {
        position: absolute;
        //border:1px solid red;

        width: 100px;
        height: 100px;
        bottom: -100px;
        left: 50%;
        margin-left: -50px;
        &:before {
          content: '';
          display: block;
          width: 27px;
          background: #ccc;
          height: 100px;
          position: absolute;
          left: 50%;
          margin-left: -13px;
        }
        &:after {
          content: '';
          display: block;
          width: 400px;
          background: #ccc;
          height: 30px;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -200px;
          border-radius: 25px;
        }
      }
    }
  }

  .scale-device-100 {
    transform: scale(1);
  }
  .scale-device-75 {
    transform: scale(0.75);
  }
  .scale-device-50 {
    transform: scale(0.5);
  }
}
</style>

<template>
  <div class="device-outer-all">
    <div class="header-top">
      <div class="dropdown-std">
        <button class="dropdown-toggle" type="button" id="dropdownMenuButton-test01" data-bs-toggle="dropdown" aria-expanded="false">
          Zoom
        </button>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuButton-test01">
          <li>50%</li>
          <li>75%</li>
          <li>100%</li>
        </ul>
      </div>
    </div>
    <div class="device-mobile-outer text-center">
      <div class="device-mobile-inner ">
        <iframe src="https://www.kleinezeitung.at/layout/klz/files/dossiers/ida/"></iframe>
      </div>
    </div>
    <div class="device-tablet-outer text-center">
      <div class="device-tablet-inner">
        <iframe src="https://www.kleinezeitung.at/layout/klz/files/dossiers/ida/"></iframe>
      </div>
    </div>
    <div class="device-desktop-outer">
      <div class="device-desktop-inner">
        <iframe src="https://www.kleinezeitung.at/layout/klz/files/dossiers/ida/"></iframe>
        <div class="device-desktop-socket"></div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
});
</script>
