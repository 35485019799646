<style lang="scss">
#debug {
  display: none;
}

#backend {
  .style-guide-outer {
    .col-lg-12,
    .col-lg-6,
    .col-lg-4,
    .col-lg-3 {
      margin-bottom: 50px;
    }
    svg {
      margin-bottom: 10px;
    }
    pre {
      background: #eee;
      font-family: SFMono-Regular, Menlo, Monaco, Consolas, 'Liberation Mono', 'Courier New', monospace;
      margin-bottom: 0;
      padding: 20px;
    }
  }
}
</style>

<template>
  <div class="style-guide-outer">
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="headline-h1">
          <h1>Beschreibung Seite</h1>
          <div class="line"><span>Name Seite</span></div>
        </div>
        <pre>
&lt;div class="headline-h1"&gt;
&lt;h1&gt;Beschreibung Seite&lt;/h1&gt;
&lt;div class="line"&gt;&lt;span&gt;Name Seite&lt;/span&gt;&lt;/div&gt;
&lt;/div&gt;</pre
        >
      </div>
      <div class="col-lg-12">
        <div class="form-group"><span class="label-input">Label Text </span> <input type="text" value="" /></div>
        <pre>
&lt;div class="form-group"&gt;
&lt;span class="label-input"&gt;Label Text &lt;/span&gt; &lt;input type="text" value="" /&gt;
&lt;/div&gt;</pre
        >
      </div>
      <div class="col-lg-4">
        <button class="btn-std">Button Name</button>
        <pre>&lt;button class="btn-std"&gt;Button Name&lt;/button&gt;</pre>
      </div>
      <div class="col-lg-4">
        <button class="btn-std btn-color-save">Button Save</button>
        <pre>&lt;button class="btn-std btn-color-save"&gt;Button Name&lt;/button&gt;</pre>
      </div>
      <div class="col-lg-4">
        <button class="btn-std btn-color-delete">Button Delete</button>
        <pre>&lt;button class="btn-std btn-color-delete"&gt;Button Delete&lt;/button&gt;</pre>
      </div>
      <div class="col-lg-3">
        <Icon :path="mdiPencil" />
        <pre>&lt;Icon :path="mdiPencil"&gt;</pre>
      </div>
      <div class="col-lg-3">
        <Icon :path="mdiDelete" />
        <pre>&lt;Icon :path="mdiDelete"&gt;</pre>
      </div>
      <div class="col-lg-3">
        <Icon :path="mdiEyeOff" />
        <pre>&lt;Icon :path="mdiEyeOff"&gt;</pre>
      </div>
      <div class="col-lg-3">
        <Icon :path="mdiEye" />
        <pre>&lt;Icon :path="mdiEye"&gt;</pre>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mdiPencil, mdiDelete, mdiArrowRight, mdiEyeOff, mdiEye } from '@mdi/js';

export default defineComponent({
  components: {},
  setup() {
    return {
      mdiPencil,
      mdiDelete,
      mdiArrowRight,
      mdiEyeOff,
      mdiEye,
    };
  },
});
</script>
