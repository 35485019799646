<style lang="scss"></style>

<template>
  <MultiUpload />
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import MultiUpload from '@/components/util/MultiUpload.vue';
import { useStore } from 'vuex';

export default defineComponent({
  components: {
    MultiUpload,
  },
  setup() {
    // const uploadfile = ref();
    // const store = useStore();
    // const upload = () => {
    //   store.dispatch('uploadFile', {
    //     file: uploadfile.value,
    //     progresshandler: (prog: any) => {
    //       console.log(prog);
    //     },
    //   });
    // };
    // const changeUploadfile = (event: any) => {
    //   uploadfile.value = event.target.files[0];
    // };
    // return {
    //   changeUploadfile,
    //   upload,
    // };
  },
});
</script>
